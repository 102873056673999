@media screen and (min-height: 568px) {
  .settings.expanded {
    position: fixed;
    height: 100vh;
    max-width: none;
    float: none;
  }
}

@media screen and (min-height: 768px) {
  .app {
    background: none;
    padding-top: 150px;
  }
}

@media screen and (min-height: 938px) {
  .app {
    h2 {
      margin-bottom: 7rem;
    }
  }
}

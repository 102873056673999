.settings {
  position: relative;
  max-width: 200px;
  float: right;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 20;
  text-align: right;
  padding: 32px 0 0 0;
  background-color: $costie-black;

  &.expanded {
    box-shadow: 0px 1px 5px 0px $costie-black, 0px 2px 2px 0px $costie-black, 0px 3px 1px -2px $costie-black;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
  }

  > svg {
    position: fixed;
    right: 8px;
    top: 8px;
  }

  svg {
    color: $costie-grey;
    cursor: pointer;
  }

  // MUI Theme overwrites
  span {
    color: $costie-white;
  }
}

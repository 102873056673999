.centered-card.form {
  .currency {
    width: 93px;
    height: 93px;
    margin-top: -46px;
    background-color: $costie-blue;
    font-size: 3.25rem;
    color: $costie-white;

    &:hover {
      background-color: $costie-grey-darker;
    }

    > span:first-of-type {
      margin-top: -15px;

      > svg.loop {
        display: block;
        position: absolute;
        bottom: 10px;
        font-size: 1rem;
      }
    }
  }

  .step-nav {
    padding-top: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;

    .push-right, .push-left {
      margin-top: 15px;

      button {
        margin-top: 0;
      }
    }
  }
}

.app {
  .white {
    color: $costie-white;
  }

  .logo {
    display: inline-block;
    font-family: $costie-font-logo;
    font-size: 4.75rem;
    margin-top: 0;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 4rem;
  }

  .centered-card {
    width: 75%;
    max-width: 300px;
    margin: 0 auto;
    overflow: visible;
    text-align: center;
    padding: 0 30px 35px 30px;
  }
}

// Colours
$costie-black: #202222;
$costie-blue: #0BA1AE;
$costie-blue-darker: #08838e;
$costie-white: white;
$costie-grey-light: lightgrey;
$costie-grey: #99A0A2;
$costie-grey-darker: #5c6161;
$costie-red: #e10050;

// Fonts
$costie-font: "Roboto", sans-serif;
$costie-font-logo: "Bubblegum Sans", sans-serif;

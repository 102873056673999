.app {
  .notification {
    color: $costie-grey;

    &.error {
      color: $costie-red;
    }

    &.above {
      margin-top: 40px;
    }

    &.below {
      margin-top: 10px;
    }
  }

  .costie-form {
    width: 100%;

    &:first-of-type {
      margin-top: 40px;
    }

    &.reduce-spacing:first-of-type {
      margin-top: 20px;
    }

    &:not(:first-of-type) {
      margin-top: 15px;
    }

    .timer {
      color: $costie-grey;
      margin: 10px 0 1rem 0;
    }

    > label + div:after {
      border-bottom: 2px solid $costie-blue;
    }

    label.focused {
      color: $costie-blue-darker;
    }

    input[type="tel"] {
      font-family: "text-security-disc";
      -webkit-text-security: disc;
    }

    &.error {
      > label {
        color: $costie-red;

        + div:before {
          border-bottom: 2px solid $costie-red;
        }
      }

      p {
        color: $costie-red;
      }
    }
  }

  label {
    color: $costie-black;
    transform: translate(0, 1.5px) scale(0.85);
    margin-bottom: 5px;
  }

  button {
    &:not(.outlined) {
      background-color: $costie-black;
      color: $costie-white;
      margin-top: 50px;

      &:hover {
        background-color: $costie-grey-darker;
      }
    }

    &.outlined {
      margin-top: 50px;
    }

    &.normalise-left {
      padding-left: 8px;
    }

    &.normalise-right {
      padding-right: 8px;
    }
  }
}

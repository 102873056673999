* {
  box-sizing: border-box;
}

html {
  background-color: $costie-blue;
  overflow-x: hidden;
}

%main-background {
  background: -webkit-repeating-linear-gradient($costie-black, $costie-black 50%, $costie-blue 50%, $costie-blue 100%);
  background: -o-repeating-linear-gradient($costie-black, $costie-black 50%, $costie-blue 50%, $costie-blue 100%);
  background: -moz-repeating-linear-gradient($costie-black, $costie-black 50%, $costie-blue 50%, $costie-blue 100%);
  background: repeating-linear-gradient($costie-black, $costie-black 50%, $costie-blue 50%, $costie-blue 100%);
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: $costie-font;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @extend %main-background;
}

.app {
  width: 100vw;
  min-height: 100vh;
  padding: 25px 0;
  position: absolute;
  z-index: 10;
  text-align: center;

  @extend %main-background;

  footer {
    text-align: center;
    font-size: 0.7rem;
    margin-top: 8px;

    a, a:hover, a:visited {
      color: $costie-black;
    }
  }

  // MUI Theme overwrites
  svg[role="presentation"] {
    font-size: 20px;

    &.before {
      margin-right: 5px;
    }

    &.next {
      margin-left: 5px;
    }
  }

  .costie-form {
    .MuiExpansionPanel {
      box-shadow: none;
      border: 0;
      margin-top: 35px;
      border-top: 1px solid $costie-grey-light;

      &:before {
        background-color: $costie-grey-light;
      }
    }

    .p0 {
      padding: 0;
    }
  }
}
